import { defineComponent } from 'vue';
import { deleteAnnualEnvironment, getAnnualEnvironment } from '@/api/dataentry-api-generated';
import AnnualEnvironmentDialog from '@/components/AnnualEnvironment/AnnualEnvironmentDialog.vue';
import AnnualEnvironmentDuplicateDialog from '@/components/AnnualEnvironment/AnnualEnvironmentDuplicateDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'AnnualEnvironments',
    components: { AnnualEnvironmentDialog, DeleteDialog, AnnualEnvironmentDuplicateDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'processorLabel',
                    name: 'processor',
                    label: 'Processor',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'yearEntry',
                    name: 'yearEntry',
                    label: 'Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'totalWaterIntakeToSite',
                    name: 'totalWaterIntakeToSite',
                    label: 'Total Water Intake to Site',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'demandMetByRecycledWater',
                    name: 'demandMetByRecycledWater',
                    label: 'Demand Met by Recycled Water',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'waterUseEfficiencyTarget',
                    name: 'waterUseEfficiencyTarget',
                    label: 'Water Use Efficiency Target',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'increaseInRecycledWaterTarget',
                    name: 'increaseInRecycledWaterTarget',
                    label: 'Increase in Recycled Water Target',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'wasteWaterDischargeFromSite',
                    name: 'wasteWaterDischargeFromSite',
                    label: 'Waste Water Discharge from Site',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dischargeToIrrigationOnSite',
                    name: 'dischargeToIrrigationOnSite',
                    label: 'Discharge to Irrigation (On-Site)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dischargeToTradeWaste',
                    name: 'dischargeToTradeWaste',
                    label: 'Discharge to Trade Waste',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'phosphorusContentUntreated',
                    name: 'phosphorusContentUntreated',
                    label: 'Phosphorus Content (Untreated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'nitrogenContentUntreated',
                    name: 'nitrogenContentUntreated',
                    label: 'Nitrogen Content (Untreated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biologicalOxygenDemandUntreated',
                    name: 'biologicalOxygenDemandUntreated',
                    label: 'Biological Oxygen Demand (Untreated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'fatsOilAndGreaseContentUntreated',
                    name: 'fatsOilAndGreaseContentUntreated',
                    label: 'Fats, Oil, and Grease Content (Untreated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'phosphorusContentTreated',
                    name: 'phosphorusContentTreated',
                    label: 'Phosphorus Content (Treated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'nitrogenContentTreated',
                    name: 'nitrogenContentTreated',
                    label: 'Nitrogen Content (Treated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biologicalOxygenDemandTreated',
                    name: 'biologicalOxygenDemandTreated',
                    label: 'Biological Oxygen Demand (Treated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'fatsOilAndGreaseContentTreated',
                    name: 'fatsOilAndGreaseContentTreated',
                    label: 'Fats, Oil, and Grease Content (Treated)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalEnergyUse',
                    name: 'totalEnergyUse',
                    label: 'Total Energy Use',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'energyEfficiencyTarget',
                    name: 'energyEfficiencyTarget',
                    label: 'Energy Efficiency Target',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'electricityFromGrid',
                    name: 'electricityFromGrid',
                    label: 'Electricity from Grid',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dieselForThermal',
                    name: 'dieselForThermal',
                    label: 'Diesel for Thermal',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dieselForEnergy',
                    name: 'dieselForEnergy',
                    label: 'Diesel for Energy',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dieselForHeavyTransport',
                    name: 'dieselForHeavyTransport',
                    label: 'Diesel for Heavy Transport',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'bituminous',
                    name: 'bituminous',
                    label: 'Bituminous (Or if Type Unknown)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'subBituminous',
                    name: 'subBituminous',
                    label: 'Sub-Bituminous',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'brown',
                    name: 'brown',
                    label: 'Brown',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'naturalGasToBoilers',
                    name: 'naturalGasToBoilers',
                    label: 'Natural Gas - To Boilers / Heaters',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'naturalGasToGenerator',
                    name: 'naturalGasToGenerator',
                    label: 'Natural Gas - To a Generator',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'naturalGasFiredGeneratorPower',
                    name: 'naturalGasFiredGeneratorPower',
                    label: 'Natural Gas Fired Generator - Power',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'naturalGasFiredGeneratorHeatCogen',
                    name: 'naturalGasFiredGeneratorHeatCogen',
                    label: 'Natural Gas Fired Generator - Heat (Cogen)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'lpg',
                    name: 'lpg',
                    label: 'LPG',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'fuelOil',
                    name: 'fuelOil',
                    label: 'Fuel Oil',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'unleadedPetrol',
                    name: 'unleadedPetrol',
                    label: 'Unleaded Petrol',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'dryWood',
                    name: 'dryWood',
                    label: 'Dry Wood',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'airDriedWood',
                    name: 'airDriedWood',
                    label: 'Green / Air-Dried Wood',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biogasToBoilers',
                    name: 'biogasToBoilers',
                    label: 'Biogas - To Boilers',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biogasToGenerator',
                    name: 'biogasToGenerator',
                    label: 'Biogas - To a Generator',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biogasFiredGeneratorPower',
                    name: 'biogasFiredGeneratorPower',
                    label: 'Biogas Fired Generator - Power',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'biogasFiredGeneratorHeatCogen',
                    name: 'biogasFiredGeneratorHeatCogen',
                    label: 'Biogas Fired Generator - Heat (Cogen)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'pvSolarElectricity',
                    name: 'pvSolarElectricity',
                    label: 'PV Solar - Electricity',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'solarThermal',
                    name: 'solarThermal',
                    label: 'Solar - Thermal',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'lng',
                    name: 'lng',
                    label: 'LNG',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'butane',
                    name: 'butane',
                    label: 'Butane',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'wasteOil',
                    name: 'wasteOil',
                    label: 'Waste Oil',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalGHGEmissions',
                    name: 'totalGHGEmissions',
                    label: 'Total GHG Emissions',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'scopeOne',
                    name: 'scopeOne',
                    label: 'Scope 1',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'scopeTwo',
                    name: 'scopeTwo',
                    label: 'Scope 2',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'scopeThree',
                    name: 'scopeThree',
                    label: 'Scope 3',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'ghgEmissionsReductionTarget',
                    name: 'ghgEmissionsReductionTarget',
                    label: 'GHG Emissions Reduction Target',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'wasteSentOffSiteOrganic',
                    name: 'wasteSentOffSiteOrganic',
                    label: 'Waste Sent Off-Site - Organic',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'wasteSentOffSiteNonOrganic',
                    name: 'wasteSentOffSiteNonOrganic',
                    label: 'Waste Sent Off-Site - Non-Organic',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'wasteSentOffSiteTotal',
                    name: 'wasteSentOffSiteTotal',
                    label: 'Waste Sent Off-Site - Total',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'solidWasteInorganicsToRecycling',
                    name: 'solidWasteInorganicsToRecycling',
                    label: 'Solid Waste Inorganics to Recycling',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'solidWasteOrganicsToComposting',
                    name: 'solidWasteOrganicsToComposting',
                    label: 'Solid Waste Organics to Composting',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'signedAPCO',
                    name: 'signedAPCO',
                    label: 'Signatory to the National Packaging Targets & Covenants (APCO)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val === true ? 'Yes' : 'No'
                },
                {
                    field: 'solidWasteToLandfillReductionTarget',
                    name: 'solidWasteToLandfillReductionTarget',
                    label: 'Solid Waste to Landfill Reduction Target',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'otherWastePractices',
                    name: 'otherWastePractices',
                    label: 'Other Waste Practices',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'noiseComplaints',
                    name: 'noiseComplaints',
                    label: 'Noise Complaints',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'odourComplaints',
                    name: 'odourComplaints',
                    label: 'Odour Complaints',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                }
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'processor',
                descending: false,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Annual Environments' : 'Annual Environment';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getAnnualEnvironment({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Annual Environment', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteAnnualEnvironment(i.uuid);
                    this.$log.addMessage('Successfully deleted Annual Environment');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
